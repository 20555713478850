.form {
  width: 300px;
  margin: 0 auto;
  position: relative;
  text-align: left;
  background: #f3f3f3;
  border: 1px solid #fff;
  border-radius: 5px;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.form h2 {
  text-align: center;
  font-size: 20px;
}

.field {
  width: 188px;
  padding: 15px 25px;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #9d9e9e;
  text-shadow: 1px 1px 0 rgba(256, 256, 256, 1);
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.field:hover {
  background: #dfe9ec;
  color: #414848;
}

.field:focus {
  background: #dfe9ec;
  color: #414848;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25);
}

.redirect-signup p {
  text-align: center;
}
